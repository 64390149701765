export const sexConf = {
  0: {
    name: "男",
    icon: "xingbienv",
    color: "#1676FF"
  },
  1: {
    name: "女",
    icon: "xingbienan",
    color: "#FB7171"
  }
};
export const expireConf = {
  0: {
    name: "已过期",
    color: "#FB7171"
  },
  1: {
    name: "有效",
    color: "#64AE08"
  },
  2: {
    name: "提前入厂",
    color: "#1676FF"
  },
  3: {
    name: "无效",
    color: "#636971 "
  }
};
