<template>
  <van-pull-refresh
    v-if="list.length"
    v-model="isPullRefreshing"
    :class="[className, 'training-info']"
    @refresh="onRefresh"
  >
    <van-list
      v-model="isLoading"
      :finished="isFinished"
      :finished-text="isError ? '' : '没有更多了'"
      :error.sync="isError"
      error-text="加载失败，请稍后再试！"
      @load="getList"
    >
      <van-cell
        v-for="(item, idx) in list"
        :key="idx"
        :class="[`${className}__row`, idx === list.length - 1 && 'border-none']"
        :border="false"
      >
        <van-form label-width="100px" colon class="contractor-person__info">
          <van-field
            :value="item.name || '--'"
            readonly
            :border="false"
            autosize
            rows="1"
            type="textarea"
          >
            <template #label>
              <span class="title">培训名称</span>
            </template>
          </van-field>
          <van-field
            :value="item.trainingDate || '--'"
            readonly
            :border="false"
            label="培训日期"
          />
          <van-field
            :value="item.resultStr || '--'"
            readonly
            :border="false"
            label="培训结果"
          />
          <van-field
            :value="item.trainTypeName || '--'"
            readonly
            :border="false"
            label="培训类别"
          />
          <van-field
            :value="item.trainLevelName || '--'"
            readonly
            :border="false"
            label="培训级别"
          />
          <van-field
            :value="item.uuserName || '--'"
            readonly
            :border="false"
            label="填写人"
          />
          <van-field
            :value="item.utime || '--'"
            readonly
            :border="false"
            label="填写时间"
          />
          <van-field
            :value="item.departmentName || '--'"
            readonly
            :border="false"
            label="填写部门"
          />
          <van-field readonly :border="false" label="附件">
            <template slot="input">
              <van-uploader v-model="item.attachments" multiple />
            </template>
          </van-field>
        </van-form>
      </van-cell>
    </van-list>
  </van-pull-refresh>
  <box-empty v-else class="contractor-person__tab-height" />
</template>

<script>
import { getPagePersonTraining } from "@/api/psm/contractor";
import { mapState } from "vuex";
import { getFileUrlForFileSystem } from "@/utils/file";
import BoxEmpty from "@/components/BoxEmpty";

export default {
  name: "TrainingInfo",
  components: { BoxEmpty },
  props: ["id"],
  data() {
    return {
      className: "contractor-person__list",
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false,
      queryInfo: {
        personId: "",
        orgCode: "",
        department: "",
        page: 1,
        size: 5,
        sort: "utime_desc;id_desc"
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  methods: {
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        this.queryInfo.personId = this.id;
        this.queryInfo.orgCode = this.userInfo.orgCode;
        const { list, total } = await getPagePersonTraining(this.queryInfo);
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        const resultMap = {
          0: "不通过",
          1: "通过"
        };
        if (list && list.length) {
          this.list = this.list.concat(
            list.map(x => {
              x.resultStr = resultMap[x.result];
              x.attachments = x?.attachments?.map(y => {
                const url = getFileUrlForFileSystem(y.id, false);
                return { ...y, url, isImage: true };
              });
              return x;
            })
          );
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    }
  }
};
</script>

<style lang="scss">
.training-info {
  .van-cell {
    overflow: visible;
  }
}
</style>
