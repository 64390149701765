<template>
  <van-form label-width="100px" colon class="contractor-person__info">
    <van-field
      :value="personInfo.idCardTypeName || '--'"
      readonly
      :border="false"
      type="text"
      label="证件类型"
    />
    <van-field
      :value="personInfo.insensitiveIdCard || '--'"
      readonly
      type="text"
      :border="false"
      label="证件号码"
    />
    <van-field
      :value="personInfo.contractorName || '--'"
      readonly
      :border="false"
      label="所属承包商"
      autosize
      rows="1"
      type="textarea"
    />
    <van-field
      :value="personInfo.organization || '--'"
      readonly
      :border="false"
      label="所属单位"
      autosize
      rows="1"
      type="textarea"
    />
    <van-field
      :value="personInfo.profession || '--'"
      readonly
      :border="false"
      label="工种"
      autosize
      rows="1"
      type="textarea"
    />
  </van-form>
</template>

<script>
export default {
  name: "PersonBaseInfo",
  props: {
    id: {
      type: String,
      required: true
    },
    personInfo: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss"></style>
