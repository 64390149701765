<template>
  <div :class="className">
    <van-nav-bar left-arrow title="承包商人员信息" @click-left="go" />
    <div :class="`${className}__person-info`">
      <div class="left">
        <img :src="personAvatar" alt="" @click="onShowAvatar" />
        <van-icon
          v-if="personInfo.gender"
          class="sex"
          class-prefix="iconfont"
          :name="sexConf[personInfo.gender].icon"
          :color="sexConf[personInfo.gender].color"
        />
      </div>
      <div class="right">
        <h3 class="title">
          <span class="person-name">{{ personInfo.contractorPersonName }}</span>
          <van-tag v-if="+personInfo.state === 2" color="#636971"
            >黑名单</van-tag
          >
          <van-tag :color="expireConf[entryStatus].color">{{
            personInfo.entranceStatusName
          }}</van-tag>
        </h3>
        <div class="phone-number line">
          <van-icon class-prefix="iconfont" name="shouji" />
          <span>{{ personInfo.mobile || "--" }}</span>
        </div>
        <div class="entry-time line">
          <van-icon class-prefix="iconfont" name="ruchang" />
          <span>{{ entryInfo.startTime || "--" }}</span>
        </div>
        <div class="leave-time line">
          <van-icon class-prefix="iconfont" name="lichang" />
          <span>{{ entryInfo.endTime || "--" }}</span>
        </div>
      </div>
    </div>
    <van-tabs
      v-model="activeName"
      animated
      swipeable
      color="#1676ff"
      @change="onTabChange"
    >
      <van-tab title="基本信息" name="person-base-info">
        <person-base-info :id="id" :person-info="personInfo"></person-base-info>
      </van-tab>
      <van-tab title="专业资质" name="qualification-info">
        <qualification-info :id="id" ref="qualification-info" />
      </van-tab>
      <van-tab title="项目信息" name="project-info">
        <project-info :id="id" ref="project-info" />
      </van-tab>
      <van-tab title="培训记录" name="training-info">
        <training-info :id="id" ref="training-info" />
      </van-tab>
    </van-tabs>
    <van-button
      v-if="tabName == 'training-info' && btnAuth.addtrainrecord"
      class="train-btn"
      size="large"
      type="info"
      square
      @click="gotoAddTrain()"
      >添加培训记录
    </van-button>
  </div>
</template>

<script>
import {
  getInfoContractorPersonById,
  getPageEntryTime
} from "@/api/psm/contractor";
import { Toast } from "vant";
import personImg from "@/assets/images/contractor-person.png";
import { getFileUrlForFileSystem } from "@/utils/file";
import { mapState } from "vuex";
import { ImagePreview } from "vant";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { expireConf, sexConf } from "./utils/constant";
import PersonBaseInfo from "./components/PersonBaseInfo.vue";
import QualificationInfo from "./components/QualificationInfo.vue";
import ProjectInfo from "./components/ProjectInfo.vue";
import TrainingInfo from "./components/TrainingInfo.vue";
import "./style/common.scss";

dayjs.extend(isBetween);
export default {
  name: "ContractorPersonDetails",
  components: { PersonBaseInfo, QualificationInfo, TrainingInfo, ProjectInfo },
  props: ["id", "type"],
  data() {
    return {
      tabName: "",
      className: "contractor-person-detail",
      sexConf,
      expireConf,
      activeName: "person-base-info",
      entryInfo: {},
      personInfo: {},
      idSouce: this.type == "list" ? "list" : ""
    };
  },
  computed: {
    ...mapState({
      btnAuth: state => state.menu.btnAuth,
      userInfo: state => state.login.userInfo
    }),
    personAvatar() {
      const fileId = this.personInfo.avatar;
      if (fileId) {
        return getFileUrlForFileSystem(fileId, false);
      } else {
        return personImg;
      }
    },
    entryStatus() {
      // 0:过期，1：正常，2：提前
      return this.personInfo?.entranceStatus || 0;
      // if (
      //   !this.entryInfo ||
      //   !this.entryInfo?.startTime ||
      //   !this.entryInfo?.endTime
      // ) {
      //   console.log("未获取到");
      //   return 2;
      // } else {
      //   const { startTime, endTime } = this.entryInfo;
      //   if (dayjs().isBetween(startTime, endTime)) {
      //     console.log("正常", startTime, endTime);
      //     return 1;
      //   } else if (dayjs().isAfter(endTime)) {
      //     console.log("过期", startTime, endTime);
      //     return 0;
      //   } else {
      //     console.log("提前", startTime, endTime);
      //     return 2;
      //   }
      // }
    }
  },
  created() {
    this.getEntryListForHead();
  },
  mounted() {
    this.getPersonInfo();
  },
  methods: {
    // 展示用户头像
    onShowAvatar() {
      ImagePreview({
        images: [this.personAvatar],
        showIndex: false,
        closeable: true
      });
    },
    gotoAddTrain() {
      this.$router.push({
        name: "ContractorTrain",
        params: { id: this.id }
      });
    },
    go() {
      if (this.idSouce == "list") {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    },
    async onTabChange(name) {
      await this.$nextTick();
      this.tabName = name;
      this.$refs[name]?.onRefresh();
    },
    async getPersonInfo() {
      try {
        if (!this.id) return Toast("未获取到人员ID");
        this.personInfo = await getInfoContractorPersonById(this.id);
      } catch (e) {
        console.log(e);
      }
    },
    async getEntryListForHead() {
      try {
        const params = {
          personId: this.id,
          orgCode: this.userInfo.orgCode,
          page: 1,
          size: 1
        };
        const { list } = await getPageEntryTime(params);
        this.entryInfo = list?.[0] || {};
        console.log(this.entryInfo);
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style lang="scss">
.contractor-person-detail {
  .train-btn {
    position: absolute;
    width: 343px;
    height: 44px;
    border-radius: 27px;
    border: 1px solid #1676ff !important;
    background: #fff !important;
    color: #1676ff !important;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__person-info {
    padding: 20px 16px;
    display: flex;
    .left {
      position: relative;
      width: 66px;
      height: 66px;
      margin-right: 16px;
      img {
        width: 66px;
        height: 66px;
        border-radius: 50%;
      }
      .sex {
        position: absolute;
        right: 0;
        bottom: 0;
        font-size: 16px;
        width: 16px;
        height: 16px;
      }
    }
    .right {
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .person-name {
          margin-right: 8px;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
          color: #2e2e4d;
          line-height: 22px;
        }
        .van-tag {
          margin-right: 4px;
        }
      }
      .line {
        font-size: 13px;
        font-weight: 400;
        text-align: left;
        color: #2e2e4d;
        line-height: 17px;
        margin-bottom: 7px;
        .iconfont {
          font-size: 14px;
          color: #aeb3c0;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
