<template>
  <van-pull-refresh
    v-if="list.length"
    v-model="isPullRefreshing"
    :class="[className, 'qualification-info']"
    @refresh="onRefresh"
  >
    <van-list
      v-model="isLoading"
      :finished="isFinished"
      :finished-text="isError ? '' : '没有更多了'"
      :error.sync="isError"
      error-text="加载失败，请稍后再试！"
      @load="getList"
    >
      <van-cell
        v-for="(item, idx) in list"
        :key="idx"
        :class="[`${className}__row`, idx === list.length - 1 && 'border-none']"
        :border="false"
      >
        <h3 class="title">
          <span>{{ item.typeName }}</span>
          <van-tag v-if="+item.reviewStatus === 1" color="#FB7171"
            >复审超期</van-tag
          >
          <van-tag v-if="+item.reviewStatus === 2" color="#FFAF11"
            >即将复审</van-tag
          >
          <van-tag v-if="+item.licenseStatus === 1" color="#FB7171"
            >已经过期</van-tag
          >
          <van-tag v-if="+item.licenseStatus === 2" color="#FFAF11"
            >即将过期</van-tag
          >
        </h3>
        <van-form label-width="100px" colon class="contractor-person__info">
          <van-field :value="item.no" readonly :border="false" label="证号" />
          <van-field
            v-if="+item.licenseType === 5"
            readonly
            :border="false"
            label="从业资格类别"
            type="textarea"
            autosize
            rows="1"
            :value="item.qualificationCategoryName || '--'"
          />
          <van-field
            v-if="+item.licenseType === 1 || +item.licenseType === 5"
            readonly
            :border="false"
            label="准驾车型"
            type="textarea"
            autosize
            rows="1"
            :value="item.carTypeValue || '--'"
          />
          <van-field
            v-if="+item.licenseType === 3"
            readonly
            :border="false"
            label="作用类别"
            type="textarea"
            autosize
            rows="1"
            :value="item.workTypeValue || '--'"
          />
          <van-field
            v-if="+item.licenseType === 3"
            readonly
            :border="false"
            label="操作项目"
            type="textarea"
            autosize
            rows="1"
            :value="item.projectTypeValue || '--'"
          />
          <van-field
            readonly
            :border="false"
            label="发证机关"
            type="textarea"
            autosize
            rows="1"
            :value="item.office || '--'"
          />
          <van-field
            readonly
            :border="false"
            label="初次领证日期"
            type="textarea"
            autosize
            rows="1"
            :value="item.getDate || '--'"
          />
          <van-field
            v-if="
              +item.licenseType === 3 ||
                +item.licenseType === 2 ||
                +item.licenseType === 9
            "
            readonly
            :border="false"
            label="下次复审日期"
            type="textarea"
            autosize
            rows="1"
            :value="item.recheckDate || '--'"
          />
          <van-field
            readonly
            :border="false"
            label="有效期开始"
            type="textarea"
            autosize
            rows="1"
            :value="item.validStartDate || '--'"
          />
          <van-field
            readonly
            :border="false"
            label="有效期结束"
            type="textarea"
            autosize
            rows="1"
            :value="item.validEndDate || '--'"
          />
        </van-form>
      </van-cell>
    </van-list>
  </van-pull-refresh>
  <box-empty v-else class="contractor-person__tab-height" />
</template>

<script>
import {
  getListContractorsPersonLicense,
  getPersonTypes
} from "@/api/psm/contractor";
import { mapState } from "vuex";
import BoxEmpty from "@/components/BoxEmpty";

export default {
  name: "QualificationInfo",
  components: { BoxEmpty },
  props: ["id"],
  data() {
    return {
      className: "contractor-person__list",
      total: 0,
      list: [],
      qualificationTypes: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false,
      queryInfo: {
        personId: ""
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  created() {
    this.getPersonTypes();
  },
  methods: {
    onRefresh() {
      this.list = [];
      this.getList();
    },
    async getPersonTypes() {
      try {
        const params = {
          orgCode: this.$store.state.login.userInfo.orgCode
        };
        this.qualificationTypes = await getPersonTypes(params);
      } catch (e) {
        console.log("getPersonLicense -> e", e);
      }
    },
    getItemByKey(arr, key, value) {
      return arr.find(v => v[key] === value) || {};
    },
    formatLicense(values) {
      return values.map(v => {
        v.typeName = this.getItemByKey(
          this.qualificationTypes,
          "value",
          v.licenseType
        ).label;
        v.file = { id: v.fileId, name: v.fileName };
        return v;
      });
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        this.queryInfo.personId = this.id;
        const list = await getListContractorsPersonLicense(this.queryInfo);
        this.list = this.formatLicense(list);

        this.isError = false;
        this.isLoading = false;
        this.isFinished = true;
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    }
  }
};
</script>

<style lang="scss">
.qualification-info {
  .van-cell__value {
    .title {
      font-size: 14px;
      font-weight: 500;
      color: #2e2e4d;
      .van-tag {
        margin-left: 10px;
      }
    }
  }
}
</style>
