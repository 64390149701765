<template>
  <van-pull-refresh
    v-if="list.length"
    v-model="isPullRefreshing"
    :class="className"
    @refresh="onRefresh"
  >
    <van-list
      v-model="isLoading"
      :finished="isFinished"
      :finished-text="isError ? '' : '没有更多了'"
      :error.sync="isError"
      error-text="加载失败，请稍后再试！"
      @load="getList"
    >
      <van-cell
        v-for="(item, idx) in list"
        :key="idx"
        :class="[`${className}__row`, idx === list.length - 1 && 'border-none']"
        :border="false"
      >
        <van-form label-width="100px" colon class="contractor-person__info">
          <h3 class="title">{{ item.projectName }}</h3>
          <van-field
            v-for="(conf, idx) in listConf"
            :key="idx"
            readonly
            autosize
            rows="1"
            :border="false"
            type="textarea"
            :label="conf.label"
            :value="item[conf.prop] || '--'"
          />
        </van-form>
      </van-cell>
    </van-list>
  </van-pull-refresh>
  <box-empty v-else class="contractor-person__tab-height" />
</template>

<script>
import { getPagePersonProject } from "@/api/psm/contractor";
import { mapState } from "vuex";
import BoxEmpty from "@/components/BoxEmpty";

export default {
  name: "ProjectInfo",
  components: { BoxEmpty },
  props: ["id"],
  data() {
    return {
      className: "contractor-person__list",
      total: 0,
      list: [],
      // 下拉刷新，上拉加载
      isPullRefreshing: false,
      isLoading: false,
      isFinished: true,
      isError: false,
      listConf: [
        {
          label: "项目编号",
          prop: "projectNo"
        },
        {
          label: "项目经理",
          prop: "managerName"
        },
        {
          label: "开始时间",
          prop: "startDate"
        },
        {
          label: "结束时间",
          prop: "endDate"
        },
        {
          label: "项目状态",
          prop: "stageName"
        }
      ],
      queryInfo: {
        personId: "",
        orgCode: "",
        department: "",
        page: 1,
        size: 5
      }
    };
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  methods: {
    onRefresh() {
      this.queryInfo.page = 1;
      this.list = [];
      this.getList();
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        this.queryInfo.personId = this.id;
        this.queryInfo.orgCode = this.userInfo.orgCode;
        const { list, total } = await getPagePersonProject(this.queryInfo);
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.queryInfo.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        console.log("getList -> error", error);
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    }
  }
};
</script>

<style scoped></style>
